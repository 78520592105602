<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('reimForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="reimForm" :model="reimForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="申请人" prop="cust_stff_aid">
            <el-select v-model="reimForm.cust_stff_aid" size="small" placeholder="请选择申请人" filterable @change="stffChange">
              <el-option v-for="item in stffList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司名称" prop="cptt_id">
            <el-select v-model="reimForm.cptt_id" size="small" placeholder="请选择公司名称" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="申请日期" prop="reim_appdate">
            <el-date-picker v-model="reimForm.reim_appdate" type="date" placeholder="选择申请日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="报销人" prop="cust_stff_bid">
            <el-select v-model="reimForm.cust_stff_bid" size="small" placeholder="请选择报销人" filterable>
              <el-option v-for="item in stffList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="总金额" prop="reim_total">
            <el-input v-model="reimForm.reim_total" disabled maxlength="30" placeholder="暂无总金额" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="报销日期" prop="reim_reidate">
            <el-date-picker v-model="reimForm.reim_reidate" type="date" placeholder="选择报销日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <el-button type="primary" plain size="small" @click="addNewTableCell">新增</el-button>
            <el-button type="danger" plain size="small" @click="delPurce">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <ReimFees :reimForm="reimForm" ref="reimFeesRef" @reimFeesRmbChange="reimFeesRmbChange" @handleSelectionChange="handleSelectionChange" />
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { reimAPI } from '@api/modules/reim';
import { cpttAPI } from '@api/modules/comptitle';
import { stffAPI } from '@/api/modules/staff';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import ReimFees from '@/views/OfficeManagement/ReimManage/TabChild/Component/ReimFees';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'reimAddMain',
  components: {
    ReimFees,
    addHeader,
    inputUser
  },
  data() {
    return {
      rules: {
        cust_stff_aid: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_stff_bid: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      reimForm: {
        cust_stff_aid: null,
        cust_stff_bid: null,
        reim_appdate: null,
        reim_reidate: null,
        cptt_id: null,
        reim_total: null,
        reim_fees_list: []
      },
      selectList: [],
      stffList: [],
      cpttList: []
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  mounted() {
    let userInfo = this.$refs.userData.userInfo;
    this.reimForm.cust_stff_aid = userInfo.stff_id;
    this.reimForm.cust_stff_bid = userInfo.stff_id;
    this.reimForm.reim_appdate = new Date();
  },
  methods: {
    initData() {
      this.getCptt();
      this.getStffUser();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName]
            .validate(valid => {
              if (valid) {
                this.saveInfo();
              } else {
                console.log('error submit!');
                this.$message.warning('请把必填内容补充完整');
                return false;
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let reimForm = JSON.parse(JSON.stringify(this.reimForm));
      reimForm = Object.assign(reimForm, staffForm);
      if (reimForm.reim_appdate) {
        reimForm.reim_appdate = parseInt(Number(new Date(reimForm.reim_appdate).getTime()) / 1000);
      }
      if (reimForm.reim_reidate) {
        reimForm.reim_reidate = parseInt(Number(new Date(reimForm.reim_reidate).getTime()) / 1000);
      }
      post(reimAPI.addReim, reimForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump('/reim_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });

            this.resetForm('reimForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('reimForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    stffChange() {
      for (let i = 0; i < this.stffList.length; i++) {
        if (this.stffList[i].stff_id === this.reimForm.cust_stff_aid) {
          this.reimForm.cptt_id = this.stffList[i].cptt_id;
        }
      }
    },
    // 选择删除值
    handleSelectionChange(val) {
      this.selectList = JSON.parse(JSON.stringify(val));
    },
    //更新总金额
    reimFeesRmbChange() {
      this.calcEcho();
    },
    // 新增一条子表空数据
    addNewTableCell() {
      let item = {
        reim_fees_type: '',
        reim_fees_rmb: '',
        reim_fees_obje: '',
        reim_dept_id: '',
        reim_fees_purp: '',
        key: this.reimForm.reim_fees_list.length
      };
      this.reimForm.reim_fees_list.push(item);
    },
    // 删除导入发票明细
    delPurce() {
      let temp = [];
      this.selectList.forEach(item => {
        temp.push(item.key);
      });
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.reimForm.reim_fees_list.splice(i, 1);
      }
      for (let i = 0; i < this.reimForm.reim_fees_list.length; i++) {
        this.reimForm.reim_fees_list[i].key = i;
      }
      this.calcEcho();
    },
    //计算总金额回显
    calcEcho() {
      let totalA = new BigNumber(0);
      for (let i = 0; i < this.reimForm.reim_fees_list.length; i++) {
        totalA = totalA.plus(this.reimForm.reim_fees_list[i].reim_fees_rmb ? this.reimForm.reim_fees_list[i].reim_fees_rmb : 0);
      }
      this.reimForm.reim_total = totalA.toFixed(2);
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            for (let i = 0; i < this.stffList.length; i++) {
              if (this.stffList[i].stff_id === this.$refs.userData.userInfo.stff_id) {
                this.reimForm.cptt_id = this.stffList[i].cptt_id;
              }
            }
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #c2c2c2;
  margin: 10px 0;
}
</style>
